import api from '@/api/form'

export default {
	name: 'ContactSubmitting',
	data () {
		return {
			limit: 10,
			page: 1
		}
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
			api.getPagination(this, this.offset, this.limit)
		},
		remove(id) {
			this.$root.$emit('ConfirmingModal', true, {
				title: "Delete item",
				text: "Sure delete this item?",
				action_title: 'Delete',
				action: () => {
					api.delete(this, id)
				}
			})
		}
	},
	computed: {
		offset () {
			return this.limit * (this.page -1)
		},
		tableData () {
			if (this.state?.contactSubmitting?.data) {
				return this.state.contactSubmitting.data
			}
			return []
		},
		countButtons () {
			if (this.state?.contactSubmitting?.meta) {
				return parseInt((this.state.contactSubmitting.meta.count / this.limit) +1)
			}
			return 1
		}
	}
}
